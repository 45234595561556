<template>
  <div class="main">
    <!-- <Head /> -->
    <div class="help-content">
      <div class="help-box">
        <ul>
          <li>
            <div class="bottom problem">
              <div class="p_content">
                <p class="p_title" style="text-align: center">家长监护</p>
                <p class="p_icon">网络游戏未成年人家长监护工程</p>
                <p class="p_icon">
                  “网络游戏未成年人家长监护工程”是一项由中华人民共和国文化部指导，网络游戏企业自主发起并参与实施，旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，构建和谐家庭关系的行业自律活动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正未成年子女沉迷游戏的行为成为可能。该项社会公益行动充分反映了中国网络游戏行业高度的社会责任感，对未成年玩家合法权益的关注及对用实际行动营造和谐社会的愿望。
                  申请监护方式 未成年人健康参与网络游戏提示
                  随着网络在青少年中的普及，未成年人接触网络游戏已经成为普遍现象。为保护未成年人健康参与游戏，在政府进一步加强行业管理的前提下，家长也应该加强监护引导。为此，我们为未成年人参与网络游戏提供以下意见：主动控制游戏时间。
                  游戏只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络中的行为和体验。
                  不参与可能耗费较多时间的游戏设置。
                  不玩大型角色扮演类游戏，不玩有PK类设置的游戏。在校学生每周玩游戏不超过2小时，每月在游戏中的花费不超过10元。不要将游戏当做精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠游戏来缓解压力。养成积极健康的游戏心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。
                  注意保护个人信息。
                  包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。
                </p>
                <p class="p_title">用户申请</p>
                <p class="p_title">申请条件</p>
                <p class="p_icon">1、申请人须为被监护未成年人的法定监护人；</p>
                <p class="p_icon">2、申请人的被监护人年龄小于18周岁；</p>
                <p class="p_icon">
                  3、申请人须为大陆公民，不含港、澳、台人士。
                </p>
                <p class="p_title">申请方式</p>
                <p class="p_icon">
                  1、电话咨询，监护人可以直接来电咨询相关服务。客服电话：400-9031-158
                </p>
                <p class="p_icon">
                  2、电邮服务，监护人可通过电子邮件发送相关信息，进行申请。邮件地址：support@gg.com。
                </p>
                <p class="p_title">监督方式</p>
                <p class="p_icon">
                  1、屏蔽账号 根据监护人意愿屏蔽相关游戏应用登陆。
                </p>
                <p class="p_icon" />
                <p class="p_icon">2、账号动态监控：</p>
                <p class="p_icon">登陆、修改密码等操作将发短信至监护人手机。</p>
                <p class="p_icon">
                  1）账号已经有认证手机的，将监护人手机替换为认证手机；
                </p>
                <p class="p_icon">
                  2）账号未认证手机，将监护人手机绑定成认证手机；
                </p>
                <p class="p_title">其他要求</p>
                <p class="p_icon">
                  1、申请人应提交较完备的申请材料，对未提供的信息要及时补充；可请熟知电脑、互联网、游戏的人员进行协助，以便提供详实资料；
                </p>
                <p class="p_icon">
                  2、申请人应保证提交的信息真实有效；对于提供虚假信息或伪造证件，监护关系证明书追究法律责任的权利。
                </p>
                <p class="p_title">申请家长监护服务</p>
                <p class="p_icon download" @click="download">
                  打包下载相关附件
                </p>
                <p class="p_icon">附件一： 被监护人信息表</p>
                <p class="p_icon">附件二： 监护人信息表</p>
                <p class="p_icon">附件三： 网络游戏未成年人家长监护申请书</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <Foot /> -->
  </div>
</template>

<script>
// import Head from '../modules/Head.vue'
// import Foot from '../modules/Foot.vue'
export default {
  name: "Guardianship",
  // components: { Head, Foot },
  methods: {
    download() {
      window.location.href = 'https://thcdn.gggamedownload.com/apk/msg.zip'
    }
  }
}
</script>

<style scoped>
.download {
  cursor: pointer;
  color: #318efd;
}
.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.575rem;
}
.help-content .help-box {
  margin: 1.25rem 0;
  overflow: hidden;
  width: 35.625rem;
  background-color: #fff;
  padding: 0.8125rem;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #eee;
}
.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  font-size: 0.5625rem;
  margin-bottom: 0.625rem;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .problem {
  padding: 0 1.25rem 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .content {
  padding: 0.625rem;
  margin-top: 0.625rem;
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  border: 0.0325rem solid #e7e7e7;
  font-size: 0.4375rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .bottom {
  border-top: 0.0325rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.p_content .p_title {
  font-size: 0.5587rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2rem;
  font-size: 0.4375rem;
}
</style>
